const APIEndpoints = {
    domain : 'https://zdajlekko.pl/',
    login : 'https://zdajlekko.pl/',
    register : 'https://zdajlekko.pl/start/nowe-konto',
    forgotPassword : 'https://zdajlekko.pl/nowe-haslo',
    newPassword : 'https://zdajlekko.pl/ustaw-haslo',
    messages : 'https://zdajlekko.pl/messages',
    welcomeUrlText:'https://zdajlekko.pl/welcome.json',
};

// const APIEndpoints = {
//     domain : 'http://zdajlekko.laminas.local/',
//     login : 'http://zdajlekko.laminas.local/',
//     register : 'http://zdajlekko.laminas.local/nowe-konto',
//     forgotPassword : 'http://zdajlekko.laminas.local/nowe-haslo',
//     newPassword : 'http://zdajlekko.laminas.local/ustaw-haslo',
//     messages : 'http://zdajlekko.laminas.local/messages',
//     welcomeUrlText:'http://zdajlekko.laminas.local/welcome.json',
// };
export default APIEndpoints