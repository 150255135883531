import React, { useContext } from 'react'
import { useForm, SubmitHandler } from "react-hook-form";
import { Link, useNavigate } from 'react-router-dom';
import APIEndpoints from '../config';
import { useState } from 'react';
import {GlobalContext} from './GlobalContext.tsx';

interface IFormWelcomeRecovery {
    userEmail: string;
  }

export function FormForgotPassword(){
    const { register, handleSubmit, formState: {errors}, setError } = useForm<IFormWelcomeRecovery>();
    const [isSubmitting,setIsSubmitting] = useState(false);
    const {globalDispatch} = useContext(GlobalContext);
    const navigate = useNavigate();

    const onSubmit: SubmitHandler<IFormWelcomeRecovery> = (e) => {
      setIsSubmitting(true);
      fetch(APIEndpoints.forgotPassword,{
        method:'POST',
        body:JSON.stringify(e),
        headers: {
        'Accept': 'application/json',
        "X-Requested-With": "XMLHttpRequest",
      },})
        .then(response => response.json())
        .then(data => {
          setIsSubmitting(false);
          if (data.messages){
            Object.keys(data.messages).map((k,i) => {
              Object.keys(data.messages[k]).map((m) => {
                setError(k, { type: "validate", message: data.messages[k][m] })
              });
            });
          }
          if (data.flashMessages){
            globalDispatch({type:'SET_MESSAGES',flashMessages:data.flashMessages});
//            console.log(data.flashMessages);
            const hasErrors = data.flashMessages.filter(e=>e.type==='danger');
            console.log(hasErrors);
            if (!hasErrors.length)
              navigate("/");
          }
        })
        .catch(error => console.error(error));
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-floating mb-3">
            <input disabled={isSubmitting} type="email" className={errors.userEmail ? 'form-control is-invalid': 'form-control'} id="loginUserEmail" placeholder="moj@adres.pl"  
              {...register("userEmail", {  required: "Adres email jest wymagany." })} 
            />
            <label htmlFor="loginUserEmail">Adres email</label>
          </div>
    
          <div className="d-grid">
            <button className="btn btn-lg btn-primary btn-login text-uppercase fw-bold mb-2" type="submit">
              {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>} Przypomnij hasło
            </button>
            <div className="text-center">
              <Link to={'/'} className="small btn btn-xs">Powrót do logowania</Link> |  <Link to={'/nowe-konto'} className="small btn btn-xs">Nie masz konta?</Link>
            </div>
          </div>
    
        </form>
      );
}