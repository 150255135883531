import * as React from "react";
import { WelcomeContainer } from "../Componets/WelcomeContainer";
import { WelcomeHeading } from "../Componets/WelcomeHeading";
import { useEffect } from 'react';
import { FormSetNewPasswordPage } from "../Componets/FormSetMewPasswordPage.tsx";

export default function SetNewPasswordPage(){
    useEffect(() => {
        document.title = 'Zmień hasło do konta - ZdajLEKko.pl';
      },[]);
    return <>
    <WelcomeContainer>
        <WelcomeHeading txtHeading="ZdajLEKko.pl" txtSubHeading="Ustaw nowe hasło do swojego konta w serwisie." />
        <FormSetNewPasswordPage />
    </WelcomeContainer>
    </>;
}