import React, { useContext, useState } from 'react'
import { useForm, SubmitHandler, UseFormGetFieldState as getFieldState } from "react-hook-form";
import { Link } from 'react-router-dom';
import APIEndpoints from '../config';
import {GlobalContext} from './GlobalContext.tsx';
import { useNavigate } from "react-router-dom";

interface IFormWelcomeRegister {
  accountName: string;
  accountEmail: string;
  accountReEmail: string;
  accountPassword: string;
  accountRePassword: string;
  RegAgree: boolean;
}

export function FormRegister(){
  const { register, handleSubmit, formState: {errors}, setError } = useForm<IFormWelcomeRegister>();
  const [isSubmitting,setIsSubmitting] = useState(false);
  const {globalDispatch} = useContext(GlobalContext);
  const navigate = useNavigate();

    const onSubmit: SubmitHandler<IFormWelcomeRegister> = async   (e) => {
      setIsSubmitting(true);
      await fetch(APIEndpoints.register,{
        method:'POST',mode:'cors',
        body:JSON.stringify(e),
        headers: {
          'Accept': 'application/json',
          "X-Requested-With": "XMLHttpRequest",
      },})
        .then(response => response.json())
        .then(data => {
          setIsSubmitting(false);
          if (data.messages){
            Object.keys(data.messages).map((k,i) => {
              Object.keys(data.messages[k]).map((m) => {
                setError(k, { type: "validate", message: data.messages[k][m] })
              });
            });
          }
          if (data.flashMessages){
            globalDispatch({type:'SET_MESSAGES',flashMessages:data.flashMessages});
//            console.log(data.flashMessages);
            const hasErrors = data.flashMessages.filter(e=>e.type==='danger');
            console.log(hasErrors);
            if (!hasErrors.length)
              navigate("/");
          }
        })
        .catch(error => console.error(error));
    };

//    console.log(formState);

    return (
      <form onSubmit={handleSubmit(onSubmit)}>
      <div className="form-floating mb-3">
        <input disabled={isSubmitting} type="text" className={errors.accountName ? 'form-control is-invalid': 'form-control'} id="loginUserName" placeholder="Moja Nazwa..." {...register("accountName", { required: "Nazwa jest wymagana." })} />
        <label htmlFor="loginUserName">Nazwa użytkownika</label>
        <div className="invalid-feedback">{errors.accountName && errors.accountName.message}</div>
      </div>
      <div className="form-floating mb-3">
        <input disabled={isSubmitting} type="email" className={errors.accountEmail ? 'form-control is-invalid': 'form-control'} id="loginUserEmail" placeholder="moj@adres.pl" {...register("accountEmail", { required: "Adres email jest wymagany." })} />
        <label htmlFor="loginUserEmail">Adres email</label>
        <div className="invalid-feedback">{errors.accountEmail && errors.accountEmail.message}</div>
      </div>
        <div className="form-floating mb-3">
          <input disabled={isSubmitting} type="email" className={errors.accountReEmail ? 'form-control is-invalid': 'form-control'} id="loginUserReEmail" placeholder="moj@adres.pl" 
          {...register("accountReEmail", {  required: "Adres email jest wymagany.", 
            validate: (value, { accountEmail }) => {//return true;
              if (accountEmail!==value) {
                return 'Adres email nie jest taki sam.';
              }
              return true;
            },
          })} />
          <label htmlFor="loginUserReEmail">Powtórz adres email</label>
          <div className="invalid-feedback">{errors.accountReEmail && errors.accountReEmail.message}</div>
        </div>
        <div className="form-floating mb-3">
          <input disabled={isSubmitting} type="password" className={errors.accountPassword ? 'form-control is-invalid': 'form-control'} id="loginUserPassword" placeholder="Hasło do logowania" 
          {...register("accountPassword", { required: true, minLength: {
            value: 6,
            message: "Hasło jest za krótkie."
          } })} />
          <label htmlFor="loginUserPassword">Hasło</label>
          <div className="invalid-feedback">{errors.accountPassword && errors.accountPassword.message}</div>
        </div>
        <div className="form-floating mb-3">
          <input disabled={isSubmitting} type="password" className={errors.accountRePassword ? 'form-control is-invalid': 'form-control'}  id="loginUserRePassword" placeholder="Hasło do logowania" 
          {...register("accountRePassword", { required: true, 
            validate: (value, { accountPassword }) => {//return true;
              if (accountPassword!==value) {
                return 'Hasła nie są takie same.';
              }
              return true;
            },
            minLength: {
            value: 6,
            message: "Hasło jest za krótkie.",
          } })} />
          <label htmlFor="loginUserRePassword">Powtórz hasło</label>
          <div className="invalid-feedback">{errors.accountRePassword && errors.accountRePassword.message}</div>
        </div>
        <div className="form-check mb-3">
          <input className={errors.RegAgree ? 'form-check-input is-invalid': 'form-check-input'}  type="checkbox" value="1" id="agreeRegTerms" {...register("RegAgree", {  required: "Potrzebujemy Twojej zgody." })} />
          <label className="form-check-label" htmlFor="agreeRegTerms">
            Oświadczam, że zapoznałem/am się z treścią  
          </label> <a href="/regulamin-2024-11-20.pdf" target='_blank' rel="noindex, nofollow">Regulaminu</a> oraz <a href="/polityka-prywatnosci.pdf" target='_blank' rel="noindex, nofollow">Polityki Prywatności</a>.
          <div className="invalid-feedback">{errors.RegAgree && errors.RegAgree.message}</div>
        </div>
    
          <div className="d-grid">
            <button className="btn btn-lg btn-primary btn-login text-uppercase fw-bold mb-2" type="submit">
                {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>} Stwórz konto
            </button>
            <div className="text-center">
              <Link to={'/nowe-haslo'} className="small btn btn-xs">Przypomnieć hasło?</Link> |  <Link to={'/'} className="small btn btn-xs">Powrót do logowania</Link>
            </div>
          </div>
    
        </form>
      );
}